import cl from "./News2.module.css";

const News2 = () => {
  return (
    <article className="article">
      <h3 className={cl.h3News1}>26 вересня 2024 </h3>
      <h2>
        11-та Міжнародна наукова конференція «Математичні проблеми механіки
        неоднорідних структур»
      </h2>
      <p>
        &emsp;&emsp;Національний комітет України з теоретичної і прикладної
        механіки виступив у якості співорганізатора 11-тої Міжнародної наукової
        конференції «Математичні проблеми механіки неоднорідних структур».
        <br />
        &emsp;&emsp; З 24 по 26 вересня 2024 року в Інституті прикладних проблем
        механіки і математики ім. Я.С. Підстригача НАН України відбулася 11-та
        Міжнародна наукова конференція «Математичні проблеми механіки
        неоднорідних структур» за підтримки Національного комітету України з
        теоретичної і прикладної механіки, Українського товариства з механіки
        руйнування матеріалів, Наукового товариства імені Шевченка.
        <br />
        &emsp;&emsp; Метою конференції було створення платформи для обговорення
        сучасних досягнень та проблемних питань у багатьох напрямках механіки, а
        також для встановлення творчих контактів між ученими різних наукових і
        науково- виробничих установ України й за її межами.
        <br />
        &emsp;&emsp; Відкриваючи захід директор Інституту прикладних проблем
        механіки і математики ім. Я.С. Підстригача академік НАН України Роман
        Кушнір висвітлив історію проведення попередніх десяти конференцій,
        починаючи від першої з них у 1983 році, та привітав її учасників від
        імені цього Інституту. Учасників конференції також привітали
        віцепрезидент НАН України академік НАН України Вячеслав Богданов і
        завідувач відділу №10 міцності матеріалів і конструкцій у водневовмісних
        середовищах Фізико-механічного інституту ім. Г.В. Карпенка НАН України
        член-кореспондент НАН України Ігор Дмитрах.
        <br />
        &emsp;&emsp; До участі у конференції зголосились 209 учасників, які
        подали 121 наукову доповідь. Серед авторів – науковці з більшості
        освітньо-наукових осередків України: Вінниця, Дніпро, Івано-Франківськ,
        Запоріжжя, Київ, Луцьк, Львів, Одеса, Рівне, Слов’янськ, Умань, Харків,
        а також науковці із Великої Британії, Польщі, Словаччини, Франції.
        Упродовж роботи конференції було виголошено 95 доповідей, серед яких 8
        пленарних.
        <br />
        &emsp;&emsp; Відбулися пленарні та секційні доповіді з наступних
        напрямків: &emsp;&emsp;
      </p>

      <ul className={cl.ulItem}>
        <li>математичне моделювання у механіці деформівних твердих тіл;</li>
        <li>математичні методи механіки та термомеханіки;</li>
        <li>
          механіка неоднорідних твердих тіл, метаматеріалів і наноструктур;
        </li>
        <li>
          механіка контактної взаємодії тіл з тріщинами й тонкими включеннями;
        </li>
        <li>динаміка неоднорідних середовищ;</li>
        <li>
          оптимізація та проектування елементів конструкцій і біомеханічних
          систем;
        </li>
        <li>
          дослідження в сфері оборони держави та відновлення інфраструктури;
        </li>
        <li>
          перспективні методи й засоби навчання у галузі механіки і прикладної
          математики.
        </li>
      </ul>
      <p>
        &emsp;&emsp;За рішенням Програмного комітету кращі наукові доповіді,
        виголошені на конференції, рекомендовано опублікувати у міжнародному
        науковому журналі «Математичні методи та фізико-механічні поля», який
        входить до категорії «А» переліку наукових фахових видань України й
        перекладається у видавництві «Springer», у наукових збірниках «Прикладні
        проблеми механіки і математики» й «Фізико-математичне моделювання та
        інформаційні технології».
        <br />
        &emsp;&emsp; У підсумковій дискусії керівники засідань, запрошені
        лектори та учасники конференції відзначили належний рівень її
        організації й високу активність учасників, що підкреслює великий інтерес
        серед наукової спільноти до проблематики конференції.
        <br />
      </p>
    </article>
  );
};
export default News2;
